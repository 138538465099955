@import "~@/styles/variables/variables.scss";



































































































































































































.page-layout {
	height: 100%;
	display: flex;
	flex-direction: column;
	.content {
		flex: 1;
		height: 0;
		padding: 0 18px;
		display: flex;
		.address-tree {
			display: flex;
			flex-direction: column;
			flex: 1;
			padding: 24px 24px 24px 12px;
			.search-modal {
				display: flex;
				margin-bottom: 12px;
				padding-right: 10px;
				.serach-ops {
					margin-left: auto;
				}
			}
			::v-deep .el-tree {
				height: 0;
				flex: 1;
				overflow-y: scroll;
				.el-tree-node {
					&__content {
						height: 42px;
						border-radius: 4px;
						.el-icon-arrow-right:not(.is-leaf) {
							color: #3f435e;
							font-size: 11px;
							font-weight: bold;
						}
						.custom-tree-node {
							display: flex;
							justify-content: space-between;
							flex: 1;
							width: 0;
							padding-right: 24px;
							align-items: center;
							color: #3f435e;
							.operate-group {
								display: none;
								.icon {
									border: 1px solid #b4c8fd;
									border-radius: 4px;
									padding: 4px 6px;
									background-color: #fff;
									color: #3f435e;
									cursor: pointer;
									margin-left: 8px;
								}
							}
						}
						&:hover {
							background-color: #f4f5fb;
							.custom-tree-node {
								.operate-group {
									display: block;
								}
							}
						}
					}
					&.is-current {
						& > .el-tree-node__content {
							.custom-tree-node {
								color: #4d6bff;
								font-weight: 600;
							}
						}
					}
				}
			}
		}
		.basic-address {
			width: 400px;
			padding: 26px 12px 24px 24px;
			border-left: 1px solid #f1f1f1;
			.value-list {
				&-item {
					& + .value-list-item {
						margin-top: 62px;
					}
					.t {
						color: #666;
					}
					.v {
						color: #333;
						margin-top: 12px;
					}
				}
			}
		}
		.icon-title {
			display: flex;
			align-items: center;
			margin-bottom: 20px;
			.title {
				font-size: 16px;
				color: #333333;
				font-weight: 500;
				margin-left: 8px;
			}
		}
		::v-deep .gc-blank-page {
			width: 100%;
			height: 100%;
		}
	}
}
